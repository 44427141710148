/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from 'react';
import {css, cx} from 'emotion';

const container = css`
  & {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  p {
    padding: 20px;
    max-width: 400px;
  }

  .native-banner {
    display: flex;
    padding: 20px 20px;
    text-decoration: none;
    width: 100%;
    max-width: 500px;
    height: 200px;
    border-radius: 8px;

    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .native-banner .native-img {
    margin-right: 20px;
    max-height: 50px;
    line-height: 0;
  }

  .native-banner .native-main {
    display: flex;
    max-width: 100%;
    margin: 0 auto;

    flex-grow: 1;
    flex-flow: row nowrap;
    align-items: center;
  }

  .native-banner .native-details {
    display: flex;
    flex-flow: column nowrap;
  }

  .native-banner .native-company {
    margin-bottom: 4px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1;
    opacity: 0.8;
  }

  .native-banner .native-desc {
    font-weight: 300;
    /*line-height: 1.4;*/
  }

  .native-banner .native-cta {
    padding: 10px 16px;
    border-radius: 3px;
    margin-top: 20px;
    align-self: flex-start;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 400;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
    transform: translateY(-1px);
  }

  .native-banner .native-cta:hover {
    box-shadow: none;
    transform: translateY(1px);
  }

  .native-source {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 5px;
    border-top: 0;
    border-top-left-radius: 3px;
    background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 5px,
        hsla(0, 0%, 0%, 0.025) 5px,
        hsla(0, 0%, 0%, 0.025) 10px
      )
      hsla(203, 11%, 95%, 0.4);
    color: #111;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 7px;
  }
`;

type Props = {
  fallback: any;
};

export default function CarbonNativeAd(props: Props) {
  const [showFallback, setShowFallback] = useState<any>(null);
  const [ad, setAd] = useState<any>(null);

  useEffect(() => {
    fetch('/api/c/')
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.ads) && data.ads.length > 0) {
          setAd(data.ads[0]);
        } else {
          throw new Error('Ad not available');
        }
      })
      .catch((error) => {
        setShowFallback(true);
      });
  }, []);

  if (showFallback) {
    return props.fallback;
  }

  if (!ad) {
    return <div className={container} />;
  }

  if (!ad.company) {
    // Some ads have no information to display
    return props.fallback;
  }

  // https://buysellads.gitbook.io/monetization/json-api-integration/json-objects
  let pixels = (ad.pixel || '')
    .split('||')
    .map((pixel, index) => (
      <img
        key={index}
        src={pixel.replace('[timestamp]', ad.timestamp)}
        style={{display: 'none'}}
        height="0"
        width="0"
      />
    ));

  let logoUrl = ad.logo;
  if (logoUrl.indexOf('https://srv.buysellads.com/') === 0) {
    logoUrl = logoUrl.replace('https://srv.buysellads.com/', '');
    logoUrl = `/api/i?url=${encodeURIComponent(logoUrl)}`;
  }

  return (
    <div className={container}>
      <p>
        Ads help pay for the servers. The link will open in a new tab and won't
        affect your progress.
      </p>
      <a
        href={ad.statlink}
        target="_blank"
        className={cx(
          'native-banner',
          css`
            background: linear-gradient(
                -30deg,
                ${ad.backgroundColor}E5,
                ${ad.backgroundColor}E5 45%,
                ${ad.backgroundColor} 45%
              )
              #fff;
          `
        )}>
        <div className="native-main">
          <img
            onError={() => {
              // Adblocker
              setShowFallback(true);
            }}
            className="native-img"
            src={logoUrl}
          />
          <div className="native-details" style={{color: ad.textColor}}>
            <span className="native-company">Sponsored by {ad.company}</span>
            <span className="native-desc">{ad.description}</span>
            <span
              className={cx(
                'native-cta',
                css`
                  color: ${ad.ctaTextColor};
                  background-color: ${ad.ctaBackgroundColor};
                `
              )}>
              {ad.callToAction}
            </span>
            {pixels}
          </div>
        </div>
      </a>
    </div>
  );
}
